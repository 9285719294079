
import http from '@/oauth-client'
import { uuid } from 'vue-uuid';
import { camelCase } from 'lodash';


const TAX_LOSS_HARVEST_PAPER_TRADE = 'TAX_LOSS_HARVEST_PAPER_TRADE';
const TAX_LOSS_HARVEST_REPLACEMENT_POSITIONS = 'TAX_LOSS_HARVEST_REPLACEMENT_POSITIONS';
const EXECUTE = 'EXECUTE';
const LOAD_USER_PORTFOLIOS = 'LOAD_USER_PORTFOLIOS';
const LOAD_USERS_PORTFOLIO_SUMMARY = 'LOAD_USERS_PORTFOLIO_SUMMARY';
const LOAD_USER_PORTFOLIO_BY_ID = 'LOAD_USER_PORTFOLIO_BY_ID';
const CREATE_USER_PORTFOLIO = 'CREATE_USER_PORTFOLIO';
//const UPDATE_USER_PORTFOLIO = 'UPDATE_USER_PORTFOLIO';
const DELETE_USER_PORTFOLIO = 'DELETE_USER_PORTFOLIO';

const TIMEOUT = 120000;

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};


export const state = {
  items: [],
  loading: false,
  deleting: null,
  processing: false,
  usersPortfolioSummary: [],
  requests: [],
  failedRequests: [],
}

export const mutations = {
  SET_ITEMS(state, newValue) {

    state.items = newValue
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue
  },
  BEGIN_REQUEST(state, { req } = {}) {
    state.requests = [...state.requests, req];
  },
  END_REQUEST(state, { resp } = {}) {
   

    const reqId = resp.reqId || resp.ReqId;
    const req = state.requests.find(x => x.id == reqId );
  
    if(!req) return;

    state.requests = [...state.requests.filter(x => x.id != reqId)];

    if(req.type === CREATE_USER_PORTFOLIO) {
      state.processing = false;
    }else  if(req.type === DELETE_USER_PORTFOLIO) {
      state.deleting = null;
    }
    let suppressCamelCase = false;
    if(!resp.failed) {

      if(req.type === LOAD_USER_PORTFOLIOS) {
        
        var originalPayload = [...(resp.payload || resp.Payload)];

        resp = camelizeKeys(resp);
        const respPayload = (resp.payload || resp.Payload);
        //state.items = (demoResp.payload || demoResp.Payload);

        respPayload.forEach((p, i) => {

          if(p.id !== '$$TOTAL$$') {
            const originalBasket = originalPayload[i]?.Data?.Basket || {};
            const indexNames = Object.keys(originalBasket.Items);
           
            try {
              p.instruments = indexNames.map(x => ({ id: x, name: x}));
              p.originalInstruments = camelizeKeys(originalPayload[i].Instruments);
            } catch(e) {
              console.error(e);
            }
            
          }

          // Get portfolio displayName from backend userSettings
          p.displayName = p.userSettings.name;
        });

        const tmp = respPayload;
      
        state.items = tmp;

      } else if(req.type === LOAD_USER_PORTFOLIO_BY_ID) {
       
        let respPayload = (resp.payload || resp.Payload);
        const sourceWeight = (respPayload.instrumentWeights || respPayload.InstrumentWeights);
        const instrumentWeights = {};
        Object.keys(sourceWeight).forEach(key => {
          var v = sourceWeight[key];
          const min = (v.min === undefined ? v.Min : v.min) != undefined ? (v.min === undefined ? v.Min : v.min) : null;
          const max = (v.max === undefined ? v.Max : v.max) != undefined ? (v.max === undefined ? v.Max : v.max) : null;
          instrumentWeights[key] = { min: min, max: max };
        });
        resp = camelizeKeys(resp);
        respPayload = (resp.payload || resp.Payload);
        respPayload.instrumentWeights = instrumentWeights;
        const tmp = [...state.items.filter(x => x.id !== req.payload.id), respPayload];
        state.items = tmp;
        suppressCamelCase = true;
      } else if(req.type === EXECUTE) {
        resp = camelizeKeys(resp);
        const respPayload = (resp.payload || resp.Payload);
        const tmp = respPayload;
        state.items = tmp;
      } else if(req.type === CREATE_USER_PORTFOLIO) {
        resp = camelizeKeys(resp);
        // TODO:
      }else if(req.type === DELETE_USER_PORTFOLIO) {
        resp = camelizeKeys(resp);
        // TODO:
      } else if(req.type === LOAD_USERS_PORTFOLIO_SUMMARY) {
        const respPayload = (resp.payload || resp.Payload);
        // const keys = Object.keys(respPayload);
        // let o = {};
        // keys.forEach(key => {
        //   o[key.toLocaleLowerCase()] = respPayload[key];
        // })
        state.usersPortfolioSummary = respPayload;
      }

      if(req.resolve) {
        if(!suppressCamelCase) {
          resp = camelizeKeys(resp);
        }
       
        const respPayload = (resp.payload || resp.Payload);
        req.resolve(respPayload);
      }
    } else {
     
        state.failedRequests = [...state.failedRequests,{...req, errorMessage: resp.errorMessage || resp.ErrorMessage}];
        if(req.reject) {
          req.reject(resp.errorMessage || resp.ErrorMessage);
        }
    }

  }
}

export const getters = {
  // loading(state) {
  //   return state.loading;
  // },
  deleting(state) {
    return state.deleting;
  },
  processing(state) {
    return state.processing;
  },
  items(state) {
    return state.items;
  },
  usersPortfolioSummary(state) {
    return state.usersPortfolioSummary;
  },
  loading(state) {
      return state.loading;
  }
}

export const actions = {
  async makeReq({ commit, rootState }, { payload, type, resolve, reject, userId } = {}) {

    const req = { 
      id: uuid.v1(),
      clientId: rootState.signalr.connectionId,
      type: type,
      payload: payload,
      userId: userId || rootState.auth.currentUser.IdentityId,
      userName: userId  ? '' : rootState.auth.currentUser.UserName,
      resolve, 
      reject
    };
    commit('BEGIN_REQUEST',  { req });
    window.setTimeout(() => {
        commit('END_REQUEST',  { resp: { reqId: req.id, failed: true, errorMessage: "Timeout reached" } });
    }, TIMEOUT);

    //console.log('demoResp', demoResp);
    //only for test
    //commit('END_REQUEST', { resp: {...demoResp, reqId: req.id}});
    await http.post('api/backend/messages/out', req);
  },
  async loadUserPortfolios({dispatch}, { userId } = {}) {
    console.debug('loadUserPortfolios', { userId });
    return new Promise( (resolve, reject) => {
      dispatch('makeReq', { payload: { }, type: LOAD_USER_PORTFOLIOS, userId, resolve, reject});
    });

    //await dispatch('makeReq', {payload: { }, type: LOAD_USER_PORTFOLIOS, userId});
  },
  loadUserPortfolio({dispatch}, { id }) {
    return new Promise( (resolve, reject) => {
      dispatch('makeReq', { payload: id, type: LOAD_USER_PORTFOLIO_BY_ID, resolve, reject});
    });
  },
  loadUsersPortfolioSummary({dispatch}, { userIDs }) {
    return new Promise( (resolve, reject) => {
      dispatch('makeReq', { payload: { userIDs }, type: LOAD_USERS_PORTFOLIO_SUMMARY, resolve, reject});
    });
  },
  createUserPortfolio({ commit,  dispatch },{input}) {
    commit('SET_PROCESSING', true);
    return new Promise( (resolve, reject) => {
      
      dispatch('makeReq', { payload: {
        id: uuid.v1(),
        data: input.constraints,
        name: input.name,
        solutionName: input.solutionName,
        riskProfileId: input.riskProfileId,
        riskTolerance: input.riskTolerance,
        instruments: input.instruments,
        instrumentWeights: input.instrumentWeights,
        weightsMode: input.weightsMode,
        weights: input.weights,
        startDate: input.startDate,
        endDate: input.endDate,
      }, type: CREATE_USER_PORTFOLIO, resolve, reject});
    });
  },
  updateUserPortfolio({ commit,  dispatch },{id, input}) {
    commit('SET_PROCESSING', true);
    return new Promise( (resolve, reject) => {
      
      dispatch('makeReq', { payload: {
        id: id,
        data: input.constraints,
        name: input.name,
        solutionName: input.solutionName,
        riskProfileId: input.riskProfileId,
        riskTolerance: input.riskTolerance,
        instruments: input.instruments,
        instrumentWeights: input.instrumentWeights,
        weightsMode: input.weightsMode,
        weights: input.weights,
        startDate: input.startDate,
        endDate: input.endDate,
      }, type: CREATE_USER_PORTFOLIO, resolve, reject});
    });
  },
  
  create({ commit, dispatch },{input}) {
    commit('SET_PROCESSING', true);
    return new Promise( (resolve, reject) => {
      http.post('api/portfolios', input).then((response)=>{
          commit('SET_PROCESSING', false);
          dispatch('load');
          resolve(response.data);
      }).catch((errors) => {
          commit('SET_PROCESSING', false);
          reject(errors);
      });
    });
  },
  loadPortfolio({ commit }, { id }) {
    commit('SET_LOADING', true);
    return new Promise( (resolve, reject) => {
      http.get('api/portfolios/' + id).then((response)=>{
        commit('SET_LOADING', false);
        resolve(response.data)
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  load({ commit }) {
    commit('SET_LOADING', true);
    return new Promise( (resolve, reject) => {
      const req = {
        
      };
      http.get('api/portfolios', { params: req}).then((response)=>{
      
        const l = response.data;
        //const userName = rootState.auth;
        // l.forEach(p => {
          
        // });
        commit('SET_ITEMS', l);
        commit('SET_LOADING', false);
        resolve(response.data)
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  delete({ commit, dispatch }, { id } = {}) {
    commit('SET_DELETING', id);
    return new Promise( (resolve, reject) => {
      http.delete(`api/portfolios/${id}`).then((response)=>{
        commit('SET_DELETING', null);
        dispatch('load');
        resolve(response.data)
      }).catch((error) => {
        commit('SET_DELETING', null);
        reject(error);
      });
    });
  },
  deleteUserPortfolio({dispatch, commit}, { id,name }) {
    commit('SET_DELETING', id);
    return new Promise( (resolve, reject) => {
      dispatch('makeReq', { payload: name, type: DELETE_USER_PORTFOLIO, resolve, reject});
    });
  },
  execute({dispatch}, { id, name }) {
    return new Promise( (resolve, reject) => {
      dispatch('makeReq', { payload:  { id, name }, type: EXECUTE, resolve, reject});
    });
  },
  taxLossHarvestPaperTrade({dispatch},payload) {
    return new Promise( (resolve, reject) => {
      dispatch('makeReq', { payload:  payload, type: TAX_LOSS_HARVEST_PAPER_TRADE, resolve, reject});
    });
    
  },
  taxLossHarvestReplacementPositions({dispatch},payload) {
    return new Promise( (resolve, reject) => {
      dispatch('makeReq', { payload:  payload, type: TAX_LOSS_HARVEST_REPLACEMENT_POSITIONS, resolve, reject});
    });
  },
  update({ commit, dispatch }, { id, input } = {}) {
    commit('SET_PROCESSING', true);
    return new Promise( (resolve, reject) => {
      http.put(`api/portfolios/${id}`, input).then((response)=>{
        commit('SET_PROCESSING', false);
        dispatch('load');
        resolve(response.data)
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        reject(error);
      });
    });
  }
}
