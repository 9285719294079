let _stack = [];
window._stack = _stack;

export function pushModal ({modalInstance, forceClose}) {
    _stack.push({ modalInstance: modalInstance, forceClose });
}

export function popModal () {
    return _stack.pop();
}

export function forceClose() {
    const copy = [..._stack];
    copy.forEach(x => {
        x.forceClose();
    });
    _stack = [];
}