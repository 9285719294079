export function parseErrors (error) {
    if(error.response.status === 500){
        return [error.response.data.Message || error.response.data.message];
    }
    let a = [];
    const errors =  (error.response.data || {}).errors || error.response.data;
    if(Array.isArray (errors)) {
        a = [...errors];
    } else{
        for (var key in errors) {
            for (var e in errors[key]) {
                a.push(errors[key][e])
            }
        }
    }
    
    return a;
}


export const chartColors = [
    '#3366CC',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC'
];

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
"Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "YTD"
];