
import http from '@/oauth-client'

export const state = {
  items: [],
  loading: false,
  deleting: null,
  processing: false
}

export const mutations = {
  SET_ITEMS(state, newValue) {
    state.items = newValue
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue
  }
}

export const getters = {
  loading(state) {
    return state.loading;
  },
  deleting(state) {
    return state.deleting;
  },
  processing(state) {
    return state.processing;
  },
  items(state) {
    return state.items;
  },
  activeItems(state) {
    return state.items.filter(x => x.calculationStatus === 'Success');
  }
}

export const actions = {
  create({ commit, dispatch }, input) {
    commit('SET_PROCESSING', true);
    return new Promise( (resolve, reject) => {
      http.post('api/risk-profiles', input).then((response)=>{
          commit('SET_PROCESSING', false);
          dispatch('load');
          resolve(response.data);
      }).catch((errors) => {
          commit('SET_PROCESSING', false);
          reject(errors);
      });
    });
  },
  load({ commit }) {
    commit('SET_LOADING', true);
    return new Promise( (resolve, reject) => {
      const req = {
        
      };
      http.get('api/risk-profiles', { params: req}).then((response)=>{
        commit('SET_ITEMS', response.data);
        commit('SET_LOADING', false);
        resolve(response.data)
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  delete({ commit, dispatch }, { id } = {}) {
    commit('SET_DELETING', id);
    return new Promise( (resolve, reject) => {
      http.delete(`api/risk-profiles/${id}`).then((response)=>{
        commit('SET_DELETING', null);
        dispatch('load');
        resolve(response.data)
      }).catch((error) => {
        commit('SET_DELETING', null);
        reject(error);
      });
    });
  },
  update({ commit, dispatch }, { id, name } = {}) {
    commit('SET_PROCESSING', true);
    return new Promise( (resolve, reject) => {
      http.put(`api/risk-profiles/${id}`, { name}).then((response)=>{
        commit('SET_PROCESSING', false);
        dispatch('load');
        resolve(response.data)
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        reject(error);
      });
    });
  }
}
