import http from '@/oauth-client'
import { debounce } from "lodash";
//import { chartColors } from '@/helpers'

const TIMEOUT = 120000;

const getDefaultState = () => {
  return {
   
    previewRequestSeq: 0,
    previewRequest: {
      
    }
  };
}


export const state = getDefaultState();

export const getters = {
  previewRequest(state){
    return state.previewRequest[state.previewRequestSeq] || null;
  },
  demo(state) {
      return state.demo
  }
}

export const mutations = {
  RESET_PREVIEW(state, { seq, portfolio } = {}) {
    state.previewRequest = {};
    if(seq) {
      state.previewRequestSeq = seq;
      state.previewRequest = {};
      state.previewRequest[seq] = {
        result: { data: portfolio.stat, id: portfolio.id },
        input: portfolio.constraints,
        pending: false,
        requesting: false,
        colors:{}
      }
    }
  },
  REQ_PREVIEW_STARTED(state, {seq}) {
    const orig = state.previewRequest[seq];
    if(orig) {
      const o = {...state.previewRequest};
      o[seq] = {...orig, requesting: true };
      state.previewRequest = o;
    }
  },
  START_PREVIEW_REQUEST(state, input) {
    const o = {...state.previewRequest};
    const prev =  o[state.previewRequestSeq] || {};
    state.previewRequestSeq = state.previewRequestSeq + 1;
    o[state.previewRequestSeq] = {
      input,
      pending: true,
      requesting: false,
      result: prev.result || null,
      colors:{}
    };
    state.previewRequest = o;
  },
  END_PREVIEW_REQUEST(state, {seq, result, error}) {

    //let colorIndex = 0;
    // let generatorColor = () => {
    //   if(!colorIndex) {
    //     colorIndex = 0;
    //   }
    //   if(colorIndex >= chartColors.length) {
    //     colorIndex = 0;
    //   }
    //   return chartColors[colorIndex++];
    // };

    let colors = {};
    if(result) {
      colors[result.id] = "#FE6C2D";
      const childrenMap = {};
      // if(result.children) {
      //   result.children.forEach(c => {
      //     childrenMap[c.id] = c;
      //     colors[c.id] = generatorColor();
      //     c.visible = true;
      //   });
      // }
      result.childrenMap = childrenMap;
    }
  

    const o = {...state.previewRequest};
    const orig = o[seq];
    if(orig) {
      o[seq] = {
        ...orig, 
        result: result, 
        pending: false, 
        requesting: false, 
        error: error,
        colors 
      };
    }
    state.previewRequest = o;
  },
  SET_MODEL(state, val) {
    state.model = val;
  }
}

export const actions = {
  async makePreviewRequest ({ dispatch, commit }, { input }) {
    commit('START_PREVIEW_REQUEST', input);
    await dispatch('debounceReqPreview');
  },
  debounceReqPreview: debounce(async ({ dispatch }) =>  {
    await dispatch('reqRreview');
  }, 1000),
  async respRreview({ commit }, { seq, data, error }) {
    console.log('preview request response');
    commit('END_PREVIEW_REQUEST', { result: data, error, seq }); 
  },
  async reqRreview ({ state, commit }) {
    
    const seq = state.previewRequestSeq;
  
    if(state.previewRequest[seq]) {
      const input = state.previewRequest[seq].input;
      commit('REQ_PREVIEW_STARTED', { seq }); 
      await http.post(`api/portfolio-previews/${seq}`, input );
    } 
    setTimeout(()=>{
      if(state.previewRequest[seq]?.pending) {        
        commit('END_PREVIEW_REQUEST', { seq, error: { internal: true } }); 
        //commit('END_PREVIEW_REQUEST', { result: demo.data, error: demo.error, seq: seq });
      }
    }, TIMEOUT);
  }
}
