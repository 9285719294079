
// import aiHttp from '@/ai-assitent-client';
import router from '@/router'
import axios from 'axios';

// import moment from 'moment'

const audioContext = new AudioContext();

let controller;
let audio;
let playing = false;
let playQueue = [];

function playText() {
    
    if(playQueue.length === 0 || playing) return;

    var playMsg = playQueue[0];
    playQueue.shift();

    if(audio) {
        audio.pause();
        audio.currentTime = 0;
    }
    if(controller) {
        controller.abort();
    }

    playing = true;
    controller = new AbortController();
    fetch(`${process.env.VUE_APP_BASE_URL}/api/speech?lang=` + playMsg.lang, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(playMsg.text),
        signal: controller.signal 
    })
    .then(response => {
        controller = null;
        if (!response.ok) {
            throw new Error("HTTP error " + response.status);
        }
        return response.blob();
    })
    .then(blob => {
        controller = null;
        audio = new Audio(URL.createObjectURL(blob));
        audio.addEventListener('ended', function() {
            // Ваш код, который будет выполняться после окончания воспроизведения
            console.log('Воспроизведение окончено');
            playing = false;
            playText();
        });
        audio.play();
       
    })
    .catch(function() {
        controller = null;
        console.log("An error occurred while fetching the speech audio.");
        playing = false;
    });
}

function sendToPlay(text, lang = 'en-US') {
    console.log('sendToPlay', {text, lang})
    if(!text) return;
    playQueue = [...playQueue, { text, lang}];
    playText();
}

function stopText() {
    if(audio) {
        audio.pause();
        audio.currentTime = 0;
        playing = false;
    }
}


export const state = {
    thinking: false,
    soundEnabled: false,
    caseState: '',
    case: 'c8wealth',
    history: [],
    messageList: [
        // { type: 'system', author: `assistant`, data: { text: `No1111` } },
        // { type: 'text', author: `assistant`, data: { text: `No.` } },
        // { type: 'text', author: `me`, data: { text: `Say yes!` } },
    ],
    optimizationRequest: null,
    saveRequest: null
}

export const mutations = {
    SELECT_TOTAL_FUNC(state, { func }) {
        state.selectTotalFunc = func;
    },
    SET_THINKING(state, val) {
        state.thinking = val;
    },
    INIT_CHAT(state, data) {
        state.caseState = data.caseState;
        state.optimizationRequest = null;
        state.additionalPromptData = data.additionalPromptData;
        state.saveRequest = null;
        state.messageList = [];
        if(state.soundEnabled) {
            const assistantMessages = data.messages.filter(x => x.author === 'assistant');
            if(assistantMessages.length > 0) {
                assistantMessages.forEach(msg => {
                    sendToPlay(msg.data.text, msg.data.lang);
                });
              
            }
        }
    },
    UPDATE_HISTORY(state, data) {
        console.log('UPDATE_HISTORY',data);
        state.history = data;
    },
    ADD_MESSAGES(state, data) {
       // if(!data.data?.text) return;
       data = data.filter(x => x.data?.text);
        console.log('ADD_MESSAGES',data);
        if(state.soundEnabled) {
            const assistantMessages = data.filter(x => x.author === 'assistant');
            if(assistantMessages.length > 0) {
                assistantMessages.forEach(msg => {
                    sendToPlay(msg.data.text, msg.data.lang);
                });
                
            } else {
                if(data.find(x => x.author === 'me')) {
                    stopText();
                } 
            }
        }
        state.messageList = [...state.messageList, ...data];
        // if(!state.optimizationRequest) {
        //     const jsonMsg = data.find(x => x.type === 'json-optimization-input');
        //     if(jsonMsg){
        //         state.optimizationRequest = jsonMsg.data.text;
        //     }
        // }
    },
    PLAY_SPEECH(state, value) {
        state.soundEnabled = value;
        if(value) {
            audioContext.resume().then(() => {
                console.log('Playback resumed successfully');
                const assistantMessages = state.messageList.filter(x => x.author === 'assistant');
                if(assistantMessages.length > 0) {
                    const lastMessage = assistantMessages[assistantMessages.length-1];
                    console.log('Play text: ', lastMessage.data.text);
                    sendToPlay(lastMessage.data.text, lastMessage.data.lang);
                }
              });
           
        } else {
            stopText();
        }
    },
    SAVE_REQUEST(state, value) {
        state.saveRequest = value;
    },
    OPTIMIZATION_SUCCESS(state) {
        state.optimizationRequest = null;
    },
    OPTIMIZATION_ERROR(state) {
        state.messageList = [...state.messageList,...[
            { type: 'system', author: `assistant`, data: { text: `Calculation ERROR` } },
        ]];
        state.optimizationRequest = null;
    },
    SET_OPTIMIZATION_REQUEST(state, request) {
        state.optimizationRequest = request;
        //state.optimizationRequestRiskProfile = cmdName;
    },
}

export const getters = {
   
}

export const actions = {
    async startChat({ dispatch, commit, state },  { caseState, aicontext }) {
        if(state.chatId) return;
        commit('SET_THINKING', true);
        commit('INIT_CHAT', { caseState });

        if(this.cancellationToken) {
            this.cancellationToken.cancel();
            this.cancellationToken = null;
          }
    
        const cancellationToken = axios.CancelToken.source();
        this.cancellationToken = cancellationToken;

        const payload = {
           
            "State": caseState,
            AiContext: aicontext
        };
        const response = await dispatch('execution/startChat', payload, { root: true });
        console.log('startChat', {payload, response});
        // const response = await aiHttp.post('ai-chat-conversations', payload, {
        //     cancelToken: cancellationToken.token
        // });
        const data = response.Payload;
        this.cancellationToken = null;
        commit('UPDATE_HISTORY', data.History);
        commit('ADD_MESSAGES', [{ type: 'text', author: `assistant`, data: { text: data.Text, lang: data.Lang}, suggestions: data.Suggestions || []  }]);
        if(caseState === "ReviewPortfolio") {
            commit('SET_THINKING', true);
        } else {
            commit('SET_THINKING', false);
        }
       
        commit('SET_OPTIMIZATION_REQUEST', null);
    },
    async sendMsg({ commit, state, dispatch, rootState }, { text, aicontext } ) {

        if(this.cancellationToken) {
            this.cancellationToken.cancel();
            this.cancellationToken = null;
          }
    
        const cancellationToken = axios.CancelToken.source();
        this.cancellationToken = cancellationToken;

        commit('SET_THINKING', true);
        commit('ADD_MESSAGES', [
            { type: 'text', author: `me`, data: { text } }
        ]);

        const payload = {
            "State": state.caseState,
            "Text": text,
            "History": state.history,
            'AdditionalPromptData': state.additionalPromptData,
            "AiContext": aicontext
        };
        const response = await dispatch('execution/getChatCompletion', payload, { root: true });
        console.log('sendMsg', {payload, response});

        // const response = await aiHttp.post('ai-chat-conversations', payload, {
        //     cancelToken: cancellationToken.token
        // });
        const data = response.Payload;
        this.cancellationToken = null;
        let skipMessage = false;
        let leaveThinking = false;
        let respText = data.Text;
        if(data.WebPage) {
            router.push(data.WebPage);
            return;
        }
        if(data.Commands?.length > 0) {
            console.log('GOT commands', {state: state.caseState, commands: data.Commands});
            if(state.caseState === 'Home' && data.Commands.find(x => x.CommandName === 'CreatePortfolio')) {
                router.push('/portfolios/new/edit');
            } else if(state.caseState ==="CreatePortfolio") {
                if(data.Commands.find(x => x.CommandName === 'Calculate Portfolio')) {
                    leaveThinking = true;
                    let parameters = data.Commands.find(x => x.CommandName === '"SetParameters"')?.CommandData
                    || data.Commands.find(x => x.CommandName === 'Calculate Portfolio')?.CommandData;
                    if(parameters) {
                        parameters = {...parameters};
                    } else {
                        parameters = {};
                    }
                    skipMessage = true;
                    parameters.MakeRequest = true;
                    commit('SET_OPTIMIZATION_REQUEST', parameters);
                    if(data.Lang === 'es-ES') {
                        commit('ADD_MESSAGES', [{ type: 'text', author: `assistant`, data: { text: 'We are preparing a preview of your portfolio for you. Please wait...', lang: data.Lang } }]);
                    } else {
                        commit('ADD_MESSAGES', [{ type: 'text', author: `assistant`, data: { text: 'We are preparing a preview of your portfolio for you. Please wait...', lang: data.Lang } }]);
                    }
                } else  if(data.Commands.find(x => x.CommandName === 'SetParameters')) {
                    let parameters = data.Commands.find(x => x.CommandName === 'SetParameters')?.CommandData;
                    if(parameters) {
                        parameters = {...parameters};
                    } else {
                        parameters = {};
                    }
                    parameters.MakeRequest = false;
                    commit('SET_OPTIMIZATION_REQUEST', parameters);
                }
          
            } else if(state.caseState ==="CreatedPortfolio" && data.Commands.find(x => x.CommandName === 'Save')) {
                skipMessage = true;
                leaveThinking = true;
                commit('SAVE_REQUEST', 'save');
                if(data.Lang === 'es-ES') {
                    commit('ADD_MESSAGES', [{ type: 'text', author: `assistant`, data: { text: 'We are saving your portfolio, please wait...', lang: data.Lang } }]);
                } else {
                    commit('ADD_MESSAGES', [{ type: 'text', author: `assistant`, data: { text: 'We are saving your portfolio, please wait...', lang: data.Lang } }]);
                }
            } else if(state.caseState ==="CreatedPortfolio" && (data.Commands.find(x => x.CommandName === 'Cancel') || data.Commands.find(x => x.CommandName === 'Home'))) {
                router.push('/');
            } else if(state.caseState === 'Home' && data.Commands.find(x => x.CommandName === 'ReviewNewPortfolio' || x.CommandName === 'ReviewPortfolio')) {
                const targetCommand = data.Commands.find(x => x.CommandName === 'Review Portfolio' && x.CommandData);
                if(!targetCommand) {
                    router.push('/select-portfolios');
                } else {
                    const portfolioName = targetCommand.CommandData["Portfolio Name"];
                    console.log('portfolioName', rootState.auth);

                    if(rootState?.portfolios?.items?.length === 0 ) {
                        await dispatch('portfolios/loadUserPortfolios', { userId: rootState.auth.currentUser.IdentityId }, { root: true });
                    }
                    if(rootState?.portfolios?.items?.length > 0 ) {
                        const portfolio = rootState.portfolios.items.find(x => x.displayName === portfolioName || x.name === portfolioName );
                        console.log('portfolio', portfolio);
                        if(portfolio) {
                            router.push('/portfolios/' + portfolio.id + '/edit');
                        } else{
                            router.push('/select-portfolios');
                        }
                    } else {
                        router.push('/select-portfolios');
                    }

                }
            } else if(state.caseState === 'Home' && data.Commands.find(x => x.CommandName === 'ShowPerformance')) {
                commit('SET_THINKING', true);

                if(rootState?.portfolios?.items?.length === 0 ) {
                    await dispatch('portfolios/loadUserPortfolios', { userId: rootState.auth.currentUser.IdentityId }, { root: true });
                }
                const aicontext = JSON.stringify(rootState?.portfolios?.items);
                await dispatch('startChat', {
                    caseState: 'ShowPerformance', 
                    aicontext: aicontext
                 });
               
                 if(state.selectTotalFunc) {
                     state.selectTotalFunc();
                 }

            } else if(state.caseState === 'Home' && data.Commands.find(x => x.CommandName === 'BrowseInstruments')) {
                router.push('/instruments');
            } else if(state.caseState === 'BrowseInstruments' && data.Commands.find(x => x.CommandName === 'Selected Instruments')) {
                const cmd = data.Commands.find(x => x.CommandName === 'Selected Instruments');
                if(cmd.CommandData) {
                    const ids =  cmd.CommandData.Instruments.split(',').map(x => x.trim()).filter(x => x);
                    console.log('Selected Instruments', ids);
                    await dispatch('instruments/load', {ids}, { root: true });
                }
            } else {
                if(data.Commands.find(x => x.CommandName === 'Cancel') || data.Commands.find(x => x.CommandName === 'Home')) {
                    router.push('/');
                }
            }
        }

        commit('UPDATE_HISTORY', data.History);

        if(!skipMessage) {
            commit('ADD_MESSAGES', [{ type: 'text', author: `assistant`, data: { text: respText, lang: data.Lang }, suggestions: data.Suggestions || [] }]);
           
        }
        if(!leaveThinking) {
            commit('SET_THINKING', false);
        }
    },
    async optimiationError({ commit } ) {
        commit('OPTIMIZATION_ERROR');
    },
    async optimiationSuccess({ commit}) {
        commit('OPTIMIZATION_SUCCESS');
    },
    async previewReady({ commit, dispatch, state }, { aicontext }) {
        console.debug('previewReady', state.caseState);
        if(state.optimizationRequest && state.caseState === "CreatePortfolio") {
            commit('SET_OPTIMIZATION_REQUEST', null);
            commit('OPTIMIZATION_SUCCESS');
            commit('SET_THINKING', true);
            await dispatch('startChat', {
                caseState: 'CreatedPortfolio', 
                aicontext: aicontext
                //additionalPromptData: mpTablePrompt + statPrompt + instrumentWeightsPrompt
             });
        } else {
            commit('SET_OPTIMIZATION_REQUEST', null);
            commit('OPTIMIZATION_SUCCESS');
            commit('SET_THINKING', true);
            await dispatch('startChat', {
                caseState: 'ReviewedPortfolio', 
                aicontext: aicontext
                //additionalPromptData: mpTablePrompt + statPrompt + instrumentWeightsPrompt
             });
        }
    }
}
