import http from '@/oauth-client'
import { parseErrors } from '../../helpers'

export const state = {
    items: [],
    loading: false,
    processing: false
}

export const mutations = {
    SET_ITEMS(state, newValue) {
        state.items = newValue
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue
    },
    SET_PROCESSING(state, newValue) {
        state.processing = newValue
    }
}

export const getters = {
    loading(state) {
        return state.loading;
    },
    processing(state) {
        return state.processing;
    },
    items(state) {
        return state.items;
    },
}

export const actions = {
    delete({ commit, dispatch }, id) {
        commit('SET_PROCESSING', true);
        return new Promise( (resolve, reject) => {
            http.delete('api/accounts/' + id).then((response)=>{
                commit('SET_PROCESSING', false);
                dispatch('load');
                resolve(response.data);
            }).catch((errors) => {
                commit('SET_PROCESSING', false);
                reject(parseErrors(errors));
            });
        });
    },
    update({ commit, dispatch }, { id, input} = {}) {
        commit('SET_PROCESSING', true);
        return new Promise( (resolve, reject) => {
            http.put('api/accounts/' + id, input).then((response)=>{
                commit('SET_PROCESSING', false);
                dispatch('load');
                resolve(response.data);
            }).catch((errors) => {
                commit('SET_PROCESSING', false);
                reject(parseErrors(errors));
            });
        });
    },
    create({ commit, dispatch }, input) {
        commit('SET_PROCESSING', true);
        return new Promise( (resolve, reject) => {
            http.post('api/accounts', input).then((response)=>{
                commit('SET_PROCESSING', false);
                dispatch('load');
                resolve(response.data);
            }).catch((errors) => {
                commit('SET_PROCESSING', false);
                reject(parseErrors(errors));
            });
        });
    },
   load({ commit }) {
        commit('SET_LOADING', true);
        return new Promise( (resolve, reject) => {
            http.get('api/accounts').then((response)=>{
                commit('SET_ITEMS', response.data);
                commit('SET_LOADING', false);
                resolve(response.data)
            }).catch((error) => {
                commit('SET_LOADING', false);
                reject(error);
            });
        });
    },
    loadById({ commit }, id) {
        commit('SET_LOADING', true);
        return new Promise( (resolve, reject) => {
            http.get('api/accounts/' + id).then((response) => {
                commit('SET_LOADING', false);
                resolve(response.data)
            }).catch((error) => {
                commit('SET_LOADING', false);
                reject(error);
            });
        });
    }
}
