/*eslint no-mixed-spaces-and-tabs: "off"*/

import axios from 'axios';
import store from '@/state/store'

const getSignalRConnectionId = () => store.state.signalr.connectionId;

const saveTokens = (access_token, refresh_token) => {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
};

const clearTokens = () => {
    console.log('clear localStorage');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.clear();
};

const isAuth = () => {
    return localStorage.getItem('access_token') ? true : false;
}

const createAxiosInstance = (config = {}) => {
    const axiosInstance = axios.create({
      baseURL: config.baseURL || '', 
      headers: {
        "Content-Type": "application/json"
        } 
    });
    
    const login = async (loginRequest) => {
        try {
            const response = await axios.post(`${axiosInstance.authBaseURL}/auth`, loginRequest);
            const access_token = response.data.Token.AccessToken;
            const refresh_token = response.data.Token.RefreshToken;
            saveTokens(access_token, refresh_token);
            return response;
        } catch (error) {
            clearTokens();
            throw error;
        }
    };

    axiosInstance.saveTokens = saveTokens;
    axiosInstance.clearTokens = clearTokens;
    axiosInstance.authBaseURL = config.authBaseURL  || config.baseURL || '';
    axiosInstance.isAuth = isAuth;
    axiosInstance.login = login;
    
    const requestInterceptor = (config) => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
            config.headers['SignalRConnId'] = getSignalRConnectionId();
        }
        return config;
    };
  
    const responseInterceptor = async (response) => {
        return response;
    };
  
    const responseErrorInterceptor = async (error) => {
        if (error.response.status === 401) {
            const refreshToken = localStorage.getItem('refresh_token');
            const accessToken = localStorage.getItem('access_token');
			
            if (refreshToken) {
			  
                try {
                    const response = await axios.post(`${axiosInstance.authBaseURL}/auth/refresh`, { refreshToken: refreshToken, accessToken: accessToken });
				
                    if (response.status === 200) {
                        console.log('refresh token success',{response: response.data, access_token: response.data.Token.AccessToken });
                        localStorage.setItem('access_token', response.data.Token.AccessToken);
                        error.config.headers.Authorization = `Bearer ${response.data.Token.AccessToken}`;
                        return axios(error.config);
                    } else {
                        console.log('refresh token failed');
                        clearTokens();
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                       
                        return Promise.reject(error);
                    }
                    
                } catch(e) {
                    console.log('refresh token failed');
                    console.error(e);
                    clearTokens();
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    return Promise.reject(error);
                }
                
            }
        }
        return Promise.reject(error);
    };
    
    axiosInstance.interceptors.request.use(requestInterceptor);
    axiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
  
    return axiosInstance;
  };
  
  export default createAxiosInstance;