//const signalR = require("@microsoft/signalr");
//import store from '@/state/store'

let connection = null;

export const state = {
   connectionId: null
}

export const mutations = {
    SET_CONNECTIONID(state, newValue) {
        state.connectionId = newValue;
    },
}

export const actions = {
    connect(/*{ dispatch, commit }*/) {
        return new Promise( (resolve) => {
           
            if(connection) {
                connection.stop();
            }
    
            //if(store.state.auth.token) {
                // const uri = process.env.VUE_APP_BASE_CALCULATION_URL + "/notifications";
                // console.log('calculation signlaR trying to connect ' + uri)
                // connection = new signalR.HubConnectionBuilder()
                // .withUrl(uri, { accessTokenFactory: () => store.state.auth.token })
                //   .withAutomaticReconnect()
                //   .build();
            
                // connection.on("UserMessage", data => {
                //   console.log('UserMessage', data);
                //    Swal.fire({
                //     title: data.value,
                //     width: 600,
                //     padding: 100,
                //     confirmButtonColor: "#556ee6",
                //     background:
                //       "#fff url(//subtlepatterns2015.subtlepatterns.netdna-cdn.com/patterns/geometry.png)"
                //   });
                // });

                // connection.on("ActiveIndexNotification", data => {
                //     console.log('ActiveIndexNotification', data);
                //     dispatch('activeIndices/load', null, { root: true });
                //   });
                
                
                // connection.start()
                //   .then(() => {
                //     console.log('calculation signlaR connected!', connection.connection.connectionId)
                //     commit('SET_CONNECTIONID', connection.connection.connectionId);
                // })
                // .catch(console.error);

                // connection.onreconnected((connectionId) => {
                //     console.log('calculation signlaR onreconnected!', connectionId)
                //     commit('SET_CONNECTIONID', connectionId);
                // });

                // connection.onclose(async (connectionId) => {
                //     console.log('calculation signlaR onclose!', connectionId)
                // });
              
            //}
            resolve();
       });
   },
   disconnect() {
        return new Promise( (resolve) => {
            if(connection) {
               connection.stop();
            }
            connection = null;
            resolve();
        });
    },
}
