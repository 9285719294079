

const getDefaultState = () => {
  return {
    
  }
};

export const state = getDefaultState();

export const mutations = {
 
}

export const getters = {
 
}

export const actions = {
 
}
