import http from '@/oauth-client'
import { parseErrors } from '../../helpers'
import { debounce } from "lodash";

export const state = {
    items: [],
    loading: false,
    processing: false,
    page: 1,
    total: 0,
    perPage: 20,
    filter:{
        keyword: null,
        accountIs: null,
        userType: null
    }
}

export const mutations = {
    SET_PAGE(state, newValue) {
        state.page = newValue;
    },
    SET_ITEMS(state, newValue) {
        state.items = newValue
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue
    },
    SET_PROCESSING(state, newValue) {
        state.processing = newValue
    },
    SET_FILTER(state, newValue) {
        state.filter = newValue
    },
    HANDLE_LOAD_RESPONSE(state, respData) {
        state.items = respData.items;
        state.total = respData.total;
    }
}

export const getters = {
    loading(state) {
        return state.loading;
    },
    processing(state) {
        return state.processing;
    },
    items(state) {
        return state.items;
    },
    perPage(state){
        return state.perPage;
    },
    page(state){
        return state.page;
    },
    total(state){
        return state.total;
    },
    filter(state){
        return state.filter;
    }
}

export const actions = {
    async setFilter({ commit, dispatch }, data) {
        //console.log('SET_FILTER', data);
        commit('SET_FILTER', data);
        commit('SET_PAGE', 1);
        await dispatch('debounceLoad');
    },
    async setPage({ commit, dispatch }, page) {
        commit('SET_PAGE', page);
        await dispatch('debounceLoad');
    },
    delete({ commit, dispatch }, id) {
        commit('SET_PROCESSING', true);
        return new Promise( (resolve, reject) => {
            http.delete('api/users/' + id).then((response)=>{
                commit('SET_PROCESSING', false);
                dispatch('load');
                resolve(response.data);
            }).catch((errors) => {
                commit('SET_PROCESSING', false);
                reject(parseErrors(errors));
            });
        });
    },
    update({ commit, dispatch }, { id, input} = {}) {
        commit('SET_PROCESSING', true);
        return new Promise( (resolve, reject) => {
            http.put('api/users/' + id, input).then((response)=>{
                commit('SET_PROCESSING', false);
                dispatch('load');
                resolve(response.data);
            }).catch((errors) => {
                commit('SET_PROCESSING', false);
                reject(parseErrors(errors));
            });
        });
    },
    create({ commit, dispatch }, input) {
        commit('SET_PROCESSING', true);
        return new Promise( (resolve, reject) => {
            http.post('api/users', input).then((response)=>{
                commit('SET_PROCESSING', false);
                dispatch('load');
                resolve(response.data);
            }).catch((errors) => {
                commit('SET_PROCESSING', false);
                reject(parseErrors(errors));
            });
        });
    },
    debounceLoad: debounce(async ({ dispatch }) =>  {
        await dispatch('load');
    }, 1000),
    load({ commit, state }) {
        commit('SET_LOADING', true);
        return new Promise( (resolve, reject) => {
            const req = {
                skip: (state.page - 1) * state.perPage,
                take: state.perPage,
                userType: state.filter.userType,
                keyword: state.filter.keyword,
                accountId: state.filter.accountId
            };
            http.get('api/users', { params: req}).then((response)=>{
                commit('HANDLE_LOAD_RESPONSE', response.data);
                commit('SET_LOADING', false);
                resolve(response.data)
            }).catch((error) => {
                commit('SET_LOADING', false);
                reject(error);
            });
        });
    },
    loadById({ commit }, id) {
        commit('SET_LOADING', true);
        return new Promise( (resolve, reject) => {
            http.get('api/users/' + id).then((response) => {
                commit('SET_LOADING', false);
                resolve(response.data)
            }).catch((error) => {
                commit('SET_LOADING', false);
                reject(error);
            });
        });
    }
}
