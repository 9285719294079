import http from '@/oauth-client'
const audioContext = new AudioContext();

let controller;
let audio;
let playing = false;
let playQueue = [];

function playText() {
    
    if(playQueue.length === 0 || playing) return;

    var playMsg = playQueue[0];
    playQueue.shift();

    if(audio) {
        audio.pause();
        audio.currentTime = 0;
    }
    if(controller) {
        controller.abort();
    }

    playing = true;
    controller = new AbortController();
    fetch(`${process.env.VUE_APP_BASE_URL}/api/speech?lang=` + playMsg.lang, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(playMsg.text),
        signal: controller.signal 
    })
    .then(response => {
        controller = null;
        if (!response.ok) {
            throw new Error("HTTP error " + response.status);
        }
        return response.blob();
    })
    .then(blob => {
        controller = null;
        audio = new Audio(URL.createObjectURL(blob));
        audio.addEventListener('ended', function() {
            // Ваш код, который будет выполняться после окончания воспроизведения
            console.log('Воспроизведение окончено');
            playing = false;
            playText();
        });
        audio.play();
       
    })
    .catch(function() {
        controller = null;
        console.log("An error occurred while fetching the speech audio.");
        playing = false;
    });
}


function sendToPlay(text, lang = 'en-US') {
    playQueue = [...playQueue, { text, lang}];
    playText();
}


function stopText() {
    if(audio) {
        audio.pause();
        audio.currentTime = 0;
        playing = false;
    }
}

export const state = {
    chatId: null,
    thinking: false,
    soundEnabled: false,
    messageList: [
        // { type: 'system', author: `assistant`, data: { text: `No1111` } },
        // { type: 'text', author: `assistant`, data: { text: `No.` } },
        // { type: 'text', author: `me`, data: { text: `Say yes!` } },
    ],
    optimizationRequest: null
}

export const mutations = {
    SET_THINKING(state, val) {
        state.thinking = val;
    },
    INIT_CHAT(state, data) {
        state.messageList = data.messages;
        state.chatId = data.chatId;
        state.optimizationRequest = null;
        if(state.soundEnabled) {
            const assistantMessages = data.messages.filter(x => x.author === 'assistant');
            if(assistantMessages.length > 0) {
                assistantMessages.forEach(msg => {
                    sendToPlay(msg.data.text, msg.data.lang);
                });
              
            }
        }
    },
    ADD_MESSAGES(state, data) {
        console.log('ADD_MESSAGES',data);
        if(state.soundEnabled) {
            const assistantMessages = data.filter(x => x.author === 'assistant');
            if(assistantMessages.length > 0) {
                assistantMessages.forEach(msg => {
                    sendToPlay(msg.data.text, msg.data.lang);
                });
                
            } else {
                if(data.find(x => x.author === 'me')) {
                    stopText();
                } 
            }
        }
        state.messageList = [...state.messageList, ...data];
        if(!state.optimizationRequest) {
            const jsonMsg = data.find(x => x.type === 'json-optimization-input');
            if(jsonMsg){
                state.optimizationRequest = jsonMsg.data.text;
            }
        }
    },
    PLAY_SPEECH(state, value) {
        state.soundEnabled = value;
        if(value) {
            audioContext.resume().then(() => {
                console.log('Playback resumed successfully');
                const assistantMessages = state.messageList.filter(x => x.author === 'assistant');
                if(assistantMessages.length > 0) {
                    const lastMessage = assistantMessages[assistantMessages.length-1];
                    sendToPlay(lastMessage.data.text, lastMessage.data.lang);
                }
              });
           
        } else {
            stopText();
        }
    },
    OPTIMIZATION_SUCCESS(state) {
        const o = [...state.messageList];
        o.pop();
        state.messageList = [...o];
        state.optimizationRequest = null;
    },
    OPTIMIZATION_ERROR(state) {
        state.messageList = [...state.messageList,...[
            { type: 'system', author: `assistant`, data: { text: `Calculation ERROR` } },
        ]];
        state.optimizationRequest = null;
    },

}

export const getters = {
   
}

export const actions = {
    async startChat({ commit, state }) {
        if(state.chatId) return;
        commit('SET_THINKING', true);
        const response = await http.post('api/chatgpt/chats');
        const { data } = response;
        commit('INIT_CHAT', data);
        commit('SET_THINKING', false);
    },
    async sendMsg({ commit, state }, text ) {
       
        commit('SET_THINKING', true);
        commit('ADD_MESSAGES', [
            { type: 'text', author: `me`, data: { text } }
        ]);
        if(!state.chatId) return;
        const response = await http.post('api/chatgpt/chats/' + state.chatId, {
            text
        });
        const { data } = response;
        const {  messages } = data;
        commit('ADD_MESSAGES', messages);
        commit('SET_THINKING', false);
    },
    async optimiationError({ commit } ) {
        commit('OPTIMIZATION_ERROR');
    },
    async optimiationSuccess({ commit }, data ) {
        commit('OPTIMIZATION_SUCCESS', data);
    },
    async previewReady({ commit }, {aicontext} = {}) {
        commit('OPTIMIZATION_SUCCESS');
        commit('SET_THINKING', true);
        const response = await http.post('api/chatgpt/chats/' + state.chatId + '/context', {
            text: aicontext
        });
        const { data } = response;
        const {  messages } = data;
        commit('ADD_MESSAGES', messages);
        commit('SET_THINKING', false);
    }
}
