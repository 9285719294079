import http from '../../oauth-client'
import explorerHttp from '@/explorer-client'

export const state = {
    currentUser: loadState('c8wealth.auth.current-user'),
    token: window.localStorage.getItem('access_token'),
    permissions: loadState('c8wealth.auth.permissions') || createEmptyPermissions(),
    managedAccounts: loadState('c8wealth.auth.managed-accounts') || []
}

if(!(state.currentUser?.IdentityId)) {
    state.currentUser = null;
    state.token = null;
    state.permissions = createEmptyPermissions();
    state.managedAccounts = [];
    window.localStorage.removeItem('c8wealth.auth.current-user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
}

if(loadState('c8wealth.auth.current-user')) {
    document.getElementById("tradingview-widget-container").style.display = 'block';
}

export const mutations = {
    SET_MANAGED_ACCOUNTS(state, newValue) {
        state.managedAccounts = newValue
        if(newValue) {
            saveState('c8wealth.auth.managed-accounts', newValue);
        }
        else {
            localStorage.removeItem('c8wealth.auth.managed-accounts');
        }
    },
    SET_TOKEN(state, newValue) {
        state.token = newValue
        if(newValue) {
            localStorage.setItem('c8wealth.auth.token', newValue);
        }
        else {
            localStorage.removeItem('c8wealth.auth.token');
        }
    },
    SET_PERMISSIONS(state, newValue) {
        state.permissions = newValue || createEmptyPermissions();
        if(newValue) {
            saveState('c8wealth.auth.permissions', newValue);
        }
        else {
            localStorage.removeItem('c8wealth.auth.permissions');
        }
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        if(newValue) {
            saveState('c8wealth.auth.current-user', newValue)
        }
        else {
            localStorage.removeItem('c8wealth.auth.current-user');
        }
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    token(state) {
        return state.token;
    },
    permissions(state) {
        return state.permissions || {};
    },
    accountPermissions(state) {
        return state.permissions.accountPermissions;
    },
    userPermissions(state) {
        return state.permissions.userPermissions;
    },
    predefinedProfilePermissions(state) {
        return state.permissions.predefinedProfilePermissions;
    },
    userType(state) {
        return state.currentUser?.Roles ? (state.currentUser.Roles.find(x => x === 'accountuser') ? 'Account' : 'C8') : '';
    },
    userRole(state) {
        return state.currentUser.Roles;
    },
    currentUser(state) {
        return state.currentUser;
    },
    managedAccounts(state) {
        return state.currentUser.managedAccounts;
    }
}

export const actions = {
    // Logs in the current user.
   logIn({ dispatch, commit }, { email, password } = {}) {
         return new Promise( (resolve, reject) => {
            http.login({username: email, password}).then(async (response)=>{
                console.log(' response.data',  response.data);
                const o = {...response.data};
                const userResp = await explorerHttp.get(`data/User/objects/current`);
                const user = userResp.data;
                const permissions = {
                    predefinedProfilePermissions:  (o.Roles && o.Roles.find(x => x === 'administrator') && !o.Roles.find(x => x === 'accountuser')) ? { allowManage: true} : { allowManage: false },
                    userPermissions: o.Roles && o.Roles.find(x => x === 'administrator') ? { allowManage: true} : { allowManage: false },
                    accountPermissions: o.Roles && o.Roles.find(x => x === 'administrator') && !o.Roles.find(x => x === 'accountuser') ? { allowManage: true} : { allowManage: false },
                    canUsePredefinedProfilesOnly: user.canUsePredefinedProfilesOnly
                }
                o.permissions = permissions;
                commit('SET_CURRENT_USER', o);
                
                //console.log('userResp', userResp);
                commit('SET_PERMISSIONS', permissions);
              //  commit('SET_MANAGED_ACCOUNTS', response.data.ManagedCompanies.map(x => x.Id));
                dispatch('signalr/connect', null, { root: true });
                await dispatch('refresh');
             
                document.getElementById("tradingview-widget-container").style.display = 'block';
                resolve(response.data.token)
            }).catch((error) => {
            
                reject(error);
            });
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        console.log('auth logOut');
        // eslint-disable-next-line no-unused-vars
        document.getElementById("tradingview-widget-container").style.display = 'none';
        commit('SET_CURRENT_USER', null);
        commit('SET_TOKEN', null);
        commit('SET_PERMISSIONS', null);
        commit('SET_MANAGED_ACCOUNTS', null);
        http.clearTokens();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    },
    async refresh({ commit, state })  {
      
        const userResp = await explorerHttp.get(`data/User/objects/current`);
        const user = userResp.data;
        const o = {...state.currentUser, Roles: user.Roles };

        const permissions = {
            predefinedProfilePermissions:  (o.Roles && o.Roles.find(x => x === 'administrator') && !o.Roles.find(x => x === 'accountuser')) ? { allowManage: true} : { allowManage: false },
            userPermissions: o.Roles && o.Roles.find(x => x === 'administrator') ? { allowManage: true} : { allowManage: false },
            accountPermissions: o.Roles && o.Roles.find(x => x === 'administrator') && !o.Roles.find(x => x === 'accountuser') ? { allowManage: true} : { allowManage: false },
            canUsePredefinedProfilesOnly: user.CanUsePredefinedProfileOnly
        }
        o.permissions = permissions;
        commit('SET_CURRENT_USER', o);
        commit('SET_PERMISSIONS', permissions);
    }
}

// ===
// Private helpers
// ===

function createEmptyPermissions(){
    return {
        accountPermissions:{
            managedAccounts:[],
            allowManage: false
        },
        
    };
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}


function loadState(key) {
    const b =  window.localStorage.getItem(key);
    if (b) {
        return JSON.parse(b);
    }
    return null;
}
